import { FC, useEffect, useState } from "react";
import useApi from "../lib/hooks/useApi";
import {
  getDeviceApiClient as apiClient,
  getDeviceManagementApiClient as deviceApiClient,
} from "../lib/api-helper/apiHelper";
import { useGlobalState } from "../lib/GlobalState";
import Authenticated from "../components/authenticated";
import { Constants } from "../constants";
import apiConfig from "../config";
import MyDevices from "../components/devices/MyDevices";

const ErrorBlock = ({
  deviceNameInvalid,
  error,
  linkError,
}: {
  error: string;
  linkError: string;
  deviceNameInvalid: boolean;
}) => {
  if (deviceNameInvalid || error || linkError) {
    return (
      <p className="text-small text-pol-red">
        {(deviceNameInvalid ? Constants.DEVICE_ID_REQUIRED : null) || error || linkError}
      </p>
    );
  }
};

const DeviceLinks: FC = () => {
  const { devices, getDevices, isLoading } = useGlobalState();
  const [device, setDevice] = useState("");
  const [deviceNameInvalid, setDeviceNameInvalid] = useState(false);
  const [deleteDevice, setDeleteDevice] = useState("");

  const [{ status, error: linkError, statusCode }, createDeviceLink, refreshCreate] = useApi(
    apiClient,
    "",
    device,
    "",
    "POST"
  );

  const [{ status: statusCheck, error, statusCode: statusCodeCheck }, checkDevice, refreshCheck] = useApi(
    deviceApiClient,
    "",
    device,
    "",
    "GET"
  );

  const [{ status: deleteStatus, statusCode: deleteCode }, removeDeviceLink, refreshDelete] = useApi(
    apiClient,
    "",
    deleteDevice,
    "",
    "POST"
  );

  useEffect(() => {
    setDeviceNameInvalid(false);
    if (statusCodeCheck === 200) {
      linkDevice();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusCodeCheck, device]);

  const linkDevice = () => {
    if (status === "fetched" || status === "error") {
      refreshCreate();
    } else {
      createDeviceLink("deviceIdLinkPost");
    }
  };

  const checkDeviceLink = () => {
    if (device.length === 0) {
      setDeviceNameInvalid(true);
      return;
    }
    if (statusCheck === "fetched" || statusCheck === "error") {
      refreshCheck();
    } else {
      checkDevice("v2SimulatorGetDevice");
    }
  };

  const unlinkDevice = (item: string) => {
    setDeleteDevice(item);
    if (deleteStatus === "fetched") {
      refreshDelete();
    } else {
      removeDeviceLink("deviceIdLinkDelete");
    }
  };

  useEffect(() => {
    getDevices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (statusCode === 201) {
      getDevices();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusCode]);

  useEffect(() => {
    if (deleteCode === 200) {
      getDevices();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteCode]);

  return (
    <Authenticated>
      <div className="max-w-3xl px-4 py-12 mx-auto sm:px-6 lg:py-16 lg:px-8">
        <h1 className="space-y-8 text-3xl font-extrabold text-gray-900 sm:text-4xl">Add a new device</h1>
        <p className="mt-3 text-lg text-gray-500">
          {Constants.ADD_DEVICE_DESC}
          <a href={`${apiConfig.reactAppDeviceManagerUrl}`} className="font-bold text-blue-500">
            {Constants.DEVICE_MANAGER}
          </a>
        </p>

        <div className="my-6 space-y-8 divide-y divide-gray-200">
          <div className="pt-6 md:grid md:grid-cols-12 md:gap-8">
            <dt className="text-base font-medium text-gray-900 md:col-span-5">
              <input
                className="w-full p-2 border border-gray-700 rounded sm:text-sm"
                value={device}
                onChange={(e) => setDevice(e.target.value)}
                placeholder="Enter a device id"
              />
              {/* @ts-ignore */}
              <ErrorBlock error={error} linkError={linkError} deviceNameInvalid={deviceNameInvalid} />
            </dt>
            <dd className="right-0 mt-2 md:mt-0 md:col-span-7">
              <button
                onClick={checkDeviceLink}
                className="flex float-right px-4 py-2 text-sm font-medium text-white bg-gray-700 border border-transparent rounded-md shadow-sm hover:bg-gray-800"
              >
                {Constants.LINK_DEVICE}
              </button>
            </dd>
          </div>
        </div>

        <MyDevices devices={devices} isLoading={isLoading} onRemove={unlinkDevice} />
      </div>
    </Authenticated>
  );
};

export default DeviceLinks;
